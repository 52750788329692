<template>
  <div class="page home">
    <div class="page-container">
      <div class="container">
        <div class="game-menu">
          <h1>{{ t('Online Party Games loved by MILLIONS Worldwide!', lang) }}</h1>
          <div class="buttons bottom">
            <form class="input-field" @submit.prevent="joinGame">
              <label for="code">🕹️ {{ t('Join a game', lang) }}</label>
              <input id="code" autocapitalize="characters" type="text" maxlength="7" placeholder="Enter your code here..." class="input code-input" v-model="gameCode" :disabled="loading">
              <button type="submit" class="btn icon ghost" :disabled="loading || gameCode.length !== 7">
                <span class="material-symbols-outlined">send</span>
              </button>
            </form>
            <span class="separator">{{ t('OR', lang) }}</span>
            <div class="button-actions">
              <router-link class="btn plain" to="/games" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
              <button class="btn" @click="openGameForm" :disabled="loading">{{ t('Create a game', lang) }}</button>
            </div>
          </div>
          <div class="streams" v-if="streamers.length">
            <div class="streams-title">
              <img class="icon" src="/assets/twitch.svg" alt="Twitch">
              {{ t('Twitch Streams', lang) }}
            </div>
            <div class="streams-list">
              <a class="stream" v-for="streamer in streamers" :href="`https://twitch.tv/${streamer.name}`" :key="streamer.id" target="_blank" rel="noopener noreferrer">
                <img :src="streamer.avatar" :alt="streamer.display_name">
                <div class="stream-name">{{ streamer.display_name }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="page-sections">
        <section class="section mobile" v-if="!user.subscribed">
          <button class="btn btn-box highlight subscribe-btn" @click="openSubscription">
            <div class="button-title">{{ t('Subscribe now', lang) }} 🤘</div>
            <div>{{ t('Get access to over', lang) }} <b>{{ t('15,000 premium cards', lang) }}</b> {{ t('and remove all ads!', lang) }}</div>
          </button>
        </section>
        <section class="section">
          <div class="wrapper">
            <h2 class="section-title">🎮 {{ t('Classic Game Play', lang) }}</h2>
            <div class="game-cards">
              <div :class="`game-card ${gameMode.id}`" v-for="gameMode in matchingGameModes" :key="gameMode.id">
                <div class="game-card-title">{{ gameMode.icon }} {{ gameMode.title }}</div>
                <div class="game-card-description">{{ gameMode.description }}</div>
                <div class="game-card-actions">
                  <router-link class="btn plain small" :to="`/games/${gameMode.id}`" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
                  <button class="btn small" @click="createGame(gameMode.id)" :disabled="loading">{{ t('New game', lang) }}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
          <div class="wrapper">
            <h2 class="section-title">🎮 {{ t('Write-in', lang) }}</h2>
            <div class="game-cards">
              <div :class="`game-card ${gameMode.id}`" v-for="gameMode in fitbGameModes" :key="gameMode.id">
                <div class="game-card-title">{{ gameMode.icon }} {{ gameMode.title }}</div>
                <div class="game-card-description">{{ gameMode.description }}</div>
                <div class="game-card-actions">
                  <router-link class="btn plain small" :to="`/games/${gameMode.id}`" :disabled="loading">{{ t('Browse games', lang) }}</router-link>
                  <button class="btn small" @click="createGame(gameMode.id)" :disabled="loading">{{ t('New game', lang) }}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section" v-if="popularDecks.length">
          <div class="wrapper">
            <h2 class="section-title">❤️ Popular Packs</h2>
            <div class="deck-list-container">
              <DeckList :decks="popularDecks" :options="deckListOptions" />
            </div>
          </div>
        </section>
        <section class="section" v-if="featuredDecks.length">
          <div class="wrapper">
            <h2 class="section-title">⭐️ Featured Packs</h2>
            <div class="deck-list-container">
              <DeckList :decks="featuredDecks" :options="deckListOptions" />
            </div>
          </div>
        </section>
        <section class="section" v-if="freeDecks.length">
          <div class="wrapper">
            <h2 class="section-title">🤘 Free Packs</h2>
            <div class="deck-list-container">
              <DeckList :decks="freeDecks" :options="deckListOptions" />
            </div>
          </div>
        </section>
        <!-- <section class="section" v-for="(mode, index) in gameModes" :key="mode.id">
          <div class="wrapper" v-if="gameModeDecks[index]?.length">
            <h2 class="section-title">{{ mode.icon }} {{ mode.title }}</h2>
            <div class="deck-list-container">
              <DeckList :decks="gameModeDecks[index]" :options="deckListOptions" />
            </div>
          </div>
        </section> -->
        <section class="section">
          <div class="wrapper">
            <div class="faq">
              <h2>{{ t('Got Questions?', lang) }}</h2>
              <button :class="`item${index === selectedFaq ? ' active' : ''}`" v-for="(item, index) in faq" @click="toggleFaq(index)" :key="item[0]">
                <div class="question">{{ t(item[0], lang) }}</div>
                <div class="answer" ref="answers" v-html="t(item[1], lang)"></div>
                <span class="material-symbols-outlined">arrow_forward_ios</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  collection, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import { firestore, sendEvent } from '../services/firebase';
import DeckList from './DeckList.vue';
import PageFooter from './PageFooter.vue';
import { showError } from '../utils/error-handling';

const FITB_GAMES = ['blanks', '3words', 'free-form', 'downtown', 'memes', 'gifs'];

export default {
  name: 'HomePage',
  data() {
    return {
      loading: false,
      loadingDecks: false,
      streamers: [],
      freeDecks: [],
      popularDecks: [],
      featuredDecks: [],
      gameModeDecks: [],
      gameCode: '',
      faq: [
        ['What games can we play on Bad Cards?', 'We currently have the following awesome games: Bad Cards, Bad Texts, Bad Questions, and Bad Lyrics.'],
        [
          'How do I play?',
          `
            All games are fill-in-the-blank type party games designed to help you and your friends/family let loose. With some slight nuances per game- each mode has a similar flow:
            <ul>
              <li>A question, prompt, or image will be shown to start each round.</li>
              <li>All players will have a hand of random response cards.</li>
              <li>One person per round will be selected as the Judge 👑.</li>
              <li>Each player will choose the funniest response card (or cards) by clicking the "Pick" button.</li>
              <li>After everyone plays their cards, the Judge chooses their favorite response!</li>
              <li>The first player to win seven rounds wins the match (but you can change that in settings!)</li>
            </ul>
          `,
        ],
        ['How many people can play?', 'Each game can have up to 16 players, and an endless number of spectators.'],
        ['How do we get a big group of people in the same game?', 'After going through the game setup flow, each game has a personalized join link that can be shared, Tweeted out, emailed, sent via AIM, or whatever communication platform you use.'],
        ['Is Bad Cards affiliated with Cards Against Humanity? Like Cards Against Humanity online?', 'Hell no. Bad Cards is not affiliated with, associated with, related to, or sponsored by Cards Against Humanity LLC. However, we are closely affiliated with your mom.'],
        ['Is Bad Gifs affiliated with What do you Meme?', 'Hell, hell no. However, see not above about our affiliation with your mom.'],
        ['Will more games be added?', 'Our team is currently working on several other game modes, tournaments, other party games, and other features to add to the site. Our goal is to connect people in fun, interactive ways- one online card game at a time.'],
        ['Do you offer anything besides adult card games?', 'Yes! We have a “Bad Kids” game mode with G- and PG-rated family-friendly content. We’re also close to implementing a rating system that can be applied to any game to filter the content based on your level of appropriateness.'],
        [
          'Why online card games with friends?',
          `
            <p>Sick of carrying a box to your friend’s house? Hate lugging tons of cards around? Ever think about all the waste that goes into printed card games? We want to alleviate those issues and make these games more accessible to all, which is why we’re proud of the millions of players we’ve attracted across the WORLD.</p>
            <p>Our goal is simple: Connect people worldwide - one party game at a time.</p>
          `,
        ],
        ['Is this a free online card game, or do I have to pay?', 'You do not need to pay to play. However, subscribing grants you access to more content, removes ads, and more.'],
        ['I’m an influencer/lead a large online community - is there any way we can collaborate?', 'Hell yeah! We’re building our creator program and would love to connect on our beta. Feel free to send a shout to info@bad.cards.'],
        [
          'What future games do you have planned?',
          `
            We've got a bunch of crazy stuff planned. We can't tip our hat on everything, but here are a few:
            <ul>
              <li><b>InstaBad</b>: The Card Queen plays a photo and you play your best photo caption.</li>
              <li><b>Definitionz [Naming in progress]</b>: A family friendly game. Everyone is given a nonsense word. You provide the best definition from your hand. You can also change settings to allow people to write their own.</li>
            </ul>
          `,
        ],
      ],
      selectedFaq: -1,
      deckListOptions: {
        slidesPerView: 1.5,
        spaceBetween: 12,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
        breakpoints: {
          1280: {
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          1170: {
            slidesPerView: 4,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
          },
          720: {
            slidesPerView: 3,
          },
          480: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['user', 'client', 'lang', 'languages', 'gameModes']),
    languageItems() {
      return this.languages.map(({ code: value }) => ({
        label: value,
        value,
      }));
    },
    matchingGameModes() {
      return this.gameModes.filter((mode) => !FITB_GAMES.includes(mode.id));
    },
    fitbGameModes() {
      return this.gameModes.filter((mode) => FITB_GAMES.includes(mode.id));
    },
  },
  components: {
    DeckList,
    PageFooter,
  },
  watch: {
    gameCode(value, old) {
      if (value.length > old.length && value.length === 3) {
        this.gameCode += '-';
      }
    },
  },
  methods: {
    ...mapActions(['openAuth', 'openGame']),
    openGameForm() {
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      this.openGame();
    },
    openSubscription() {
      window.dispatchEvent(new CustomEvent('open-subscription'));
    },
    toggleFaq(index) {
      const el = this.$refs.answers[this.selectedFaq];
      if (el) {
        el.style.maxHeight = null;
      }
      this.selectedFaq = index === this.selectedFaq ? -1 : index;
      if (this.selectedFaq > -1) {
        const answer = this.$refs.answers[this.selectedFaq];
        if (answer) {
          answer.style.maxHeight = `${answer.scrollHeight}px`;
        }
      }
    },
    async getPopularDecks(decks) {
      let favorites;
      if (decks.length) {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(5),
        );
        favorites = await getDocs(favoritesQuery);
      }
      this.popularDecks = decks.map((deck) => ({
        ...deck,
        favorite: !!favorites?.docs.find((doc) => doc.get('deck_id') === deck.id),
      }));
    },
    async getFreeDecks(decks) {
      let favorites;
      if (decks.length) {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(5),
        );
        favorites = await getDocs(favoritesQuery);
      }
      this.freeDecks = decks.map((deck) => ({
        ...deck,
        favorite: !!favorites?.docs.find((doc) => doc.get('deck_id') === deck.id),
      }));
    },
    async getFeaturedDecks(decks) {
      let favorites;
      if (decks.length) {
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(5),
        );
        favorites = await getDocs(favoritesQuery);
      }
      this.featuredDecks = decks.map((deck) => ({
        ...deck,
        favorite: !!favorites?.docs.find((doc) => doc.get('deck_id') === deck.id),
      }));
    },
    async fetchDecks() {
      this.loadingDecks = true;
      try {
        const decks = await this.client.get('/data/home');
        this.getFeaturedDecks(decks.featured);
        this.getFreeDecks(decks.free);
        this.getPopularDecks(decks.popular);
      } catch (error) {
        showError(error);
      }
      this.loadingDecks = false;
    },
    async getGameModeDecks() {
      this.loadingDecks = true;
      this.gameModeDecks = await Promise.all(this.gameModes.map(async (mode) => {
        const decksQuery = query(
          collection(firestore, 'decks'),
          where('game_modes', 'array-contains', mode.id),
          orderBy('game_count', 'desc'),
          limit(5),
        );
        const result = await getDocs(decksQuery);
        if (result.empty) {
          return [];
        }
        const decks = result.docs.map((doc) => doc.data());
        const favoritesQuery = query(
          collection(firestore, 'favorites'),
          where('user_id', '==', this.user.id),
          where('deck_id', 'in', decks.map((deck) => deck.id)),
          limit(decks.length),
        );
        const favorites = await getDocs(favoritesQuery);
        return decks.map((deck) => ({
          ...deck,
          favorite: !!favorites.docs.find((doc) => doc.get('deck_id') === deck.id),
        }));
      }));
      this.loadingDecks = false;
    },
    async joinGame() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const game = await this.client.post('/games/join', {
          code: this.gameCode.toUpperCase(),
        });
        this.$router.push(`/g/${game.id}`);
        sendEvent('join-game', { game_id: game.id });
      } catch (error) {
        console.warn(error);
      }
      this.gameCode = '';
      this.loading = false;
    },
    async createGame(mode, deck, name) {
      if (this.user.type === 'anonymous') {
        this.openAuth();
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        const settings = {
          name: name || `${this.user.name || 'Unknown'}'s game`,
          mode,
          decks: deck ? [deck.id] : null,
          is_manual: true,
        };
        const game = await this.client.post('/games', settings);
        settings.id = game.id;
        sendEvent('create-game', settings);
        this.$router.push(`/g/${game.id}`);
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async getStreamers() {
      clearTimeout(this.streamersTO);
      try {
        this.streamers = await this.client.get('/streamers');
      } catch (error) {
        console.warn(error);
      }
      this.streamersTO = setTimeout(this.getStreamers, 30000);
    },
    onDeckDeleted() {
      this.fetchDecks();
    },
  },
  mounted() {
    // this.getStreamers();
    this.onDeckDeleted();
    window.addEventListener('deck-deleted', this.onDeckDeleted);
  },
  beforeDestroy() {
    window.removeEventListener('deck-deleted', this.onDeckDeleted);
    clearTimeout(this.streamersTO);
  },
};
</script>

<style scoped>
.page {
  padding: 24px;
}

.container {
  position: relative;
  background: rgba(90,76,212,1);
  background: linear-gradient(142deg, rgba(90,76,212,1) 0%, rgba(60,51,115,1) 50%);
  border-radius: 16px;
  padding: 48px;
  width: 1200px;
  max-width: 100%;
  margin: auto;
}
.container .logo {
  display: block;
  width: 360px;
  max-width: 100%;
  margin: 0 auto;
}
.container h1 {
  margin: 32px 0;
  text-align: center;
}
.container .back-btn {
  position: absolute;
  top: 24px;
  left: 24px;
}

.section {
  margin-top: 48px;
}
.section.mobile {
  display: none;
}
.section .section-title {
  margin-bottom: 12px;
}

.subscribe-btn {
  font-size: 18px;
  padding: 16px 24px;
}
.subscribe-btn .button-title {
  font-size: 24px;
}

.game-cards {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.game-cards .game-card {
  display: flex;
  flex-direction: column;
  width: calc(25% - 9px);
  min-height: 154px;
  text-align: left;
  border-radius: 16px;
  padding: 16px 24px;
  background-color: rgba(90,76,212,1);
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-cards-backs.png') no-repeat center/cover;
}
.game-cards .game-card.lyrics {
  background: linear-gradient(142deg, rgba(236, 51, 126, 0.85) 0%, rgba(204, 8, 86, 0.85) 50%), url('@/assets/bad-lyrics-backs.png') no-repeat center/cover;
}
.game-cards .game-card.questions {
  background: linear-gradient(142deg, rgba(253,186,53,0.85) 0%, rgba(222, 148, 1, 0.85) 50%), url('@/assets/bad-questions-backs.png') no-repeat center/cover;
}
.game-cards .game-card.texts {
  background: linear-gradient(142deg, rgba(76, 209, 253, 0.85) 0%, rgba(0, 160, 214, 0.85) 50%), url('@/assets/bad-texts-backs.png') no-repeat center/cover;
}
.game-cards .game-card.bubbles {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-bubbles-backs.png') no-repeat center/cover;
}
.game-cards .game-card.memes {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-memes-backs.png') no-repeat center/cover;
}
.game-cards .game-card.gifs {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-gifs-backs.png') no-repeat center/cover;
}
.game-cards .game-card.girls {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-girls-backs.png') no-repeat center/cover;
}
.game-cards .game-card.kids {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-kids-backs.png') no-repeat center/cover;
}
.game-cards .game-card.blanks {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/bad-blanks-backs.png') no-repeat center/cover;
}
.game-cards .game-card.downtown {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/downtown-dictionary-backs.png') no-repeat center/cover;
}
.game-cards .game-card.three-words {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/3words-backs.png') no-repeat center/cover;
}
.game-cards .game-card.free-form {
  background: linear-gradient(142deg, rgba(90,76,212,0.85) 0%, rgba(60,51,115,0.85) 50%), url('@/assets/dafuq-backs.png') no-repeat center/cover;
}
.game-cards .game-card .game-card-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}
.game-cards .game-card .game-card-actions {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;
  gap: 8px;
  padding-top: 16px;
}
.game-cards .game-card .btn.plain {
  background: rgba(0, 0, 0, 0.32);
}

.container p {
  font-size: 20px;
  margin: 64px 0;
  line-height: 1.75;
}

.form .form-step .actions {
  justify-content: center;
}

.game-options {
  margin-top: 48px;
}
.game-options h2 {
  margin-bottom: 24px;
}

.username-form {
  text-align: center;
}
.username-form .label {
  margin-bottom: 16px;
}
.username-form .providers {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.username-form .form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.username-form .actions {
  justify-content: center;
}

.game-form {
  text-align: left;
}

.input-field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  text-align: left;
  max-width: 282px;
}
.input-field label {
  display: block;
  width: 100%;
}
.input-field .input {
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px 24px;
}
.buttons .separator {
  margin-bottom: 12px;
}
.buttons.bottom {
  flex-direction: column;
}
.buttons.bottom .input-field {
  justify-content: center;
  text-align: center;
}
.buttons.bottom .input-field .input {
  flex-grow: 1;
}
.buttons .button-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.game-modes {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.game-modes .game-mode {
  width: calc(50% - 8px);
  text-align: left;
  background: #5f51d7;
  padding: 12px 16px;
  border-radius: 8px;
  opacity: 0.5;
}

.game-modes .game-mode[selected] {
  opacity: 1;
}

.game-modes .game-mode .game-mode-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.game-browser {
  width: 100%;
  margin-top: 48px;
}

.faq {
  margin-top: 96px;
}
.faq h2 {
  font-size: 48px;
  text-align: center;
}
.faq .item {
  position: relative;
  display: block;
  width: 100%;
  padding: 24px 48px 12px 24px;
  font-size: 18px;
  background: rgba(94, 81, 215, 0.48);
  border-radius: 16px;
  margin-top: 24px;
  text-align: left;
}
.faq .item .question {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
}
.faq .item .answer {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.faq .item .material-symbols-outlined {
  position: absolute;
  top: 24px;
  right: 12px;
  transition: transform 0.2s ease-out;
}
.faq .item.active .material-symbols-outlined {
  transform: rotateZ(90deg);
}

.games-list {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: auto;
  margin-top: 24px;
}

.games-list .game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 24px;
  background: rgba(0, 0, 0, 0.48);
  border-radius: 8px;
  margin-bottom: 8px;
}

.games-list .game .game-name {
  font-size: 16px;
  margin-bottom: 4px;
}

.games-list .game .game-settings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.games-list .game .game-stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}
.games-list .game .game-stat {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(0, 0, 0, 0.48);
  border-radius: 16px;
  font-size: 14px;
  padding: 4px 8px;
}
.games-list .game .game-stat .material-symbols-outlined {
  font-size: 16px;
}

.empty-state {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  text-align: center;
}

.streams {
  margin-top: 40px;
}
.streams .streams-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
}
.streams .streams-title .icon {
  width: 18px;
  margin-right: 8px;
}
.streams .streams-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.streams .stream {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 3px solid #9146FF;
}
.streams .stream img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.streams .stream .stream-name {
  position: absolute;
  display: none;
  padding: 4px 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.48);
  top: 100%;
  margin-top: 8px;
  left: 50%;
  transform: translateX(-50%);
}
.streams .stream:hover .stream-name {
  display: block;
}

@media screen and (max-width: 1280px) {
  .game-cards .game-card {
    width: calc(33.333333% - 8px);
  }
  .deck-list-container {
    margin: 0 -24px;
  }
}

@media screen and (max-width: 800px) {
  .game-cards .game-card {
    width: calc(50% - 6px);
  }
}

@media screen and (max-width: 640px) {
  .buttons,
  .buttons.bottom {
    flex-direction: column;
    align-items: center;
  }
  .buttons .separator {
    margin-bottom: 0;
  }
  .input-field {
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 580px) {
  .game-cards .game-card {
    width: 100%;
  }
  .container {
    padding: 48px 24px;
  }
  .container h1 {
    font-size: 20px;
    margin-top: 0;
  }
  .container .logo {
    display: none;
  }
  .game-modes .game-mode {
    width: 100%;
  }
  .section.mobile {
    display: block;
  }
}
</style>
